@import './_all_vars.sass'

h1
  font-family: inherit
  font-size: 40px
  font-weight: 400
  color: $recall-heading

p
  font-size: 14px

  &.note
    color: $recall-text-lighter
    font-style: italic

a
  font-size: 14px

.examples
  margin: 2rem 0
  text-align: left
  gap: 8px
  display: flex
  flex-direction: column

  h3
    margin: 0

  .question
    width: 100%
    padding: 8px
    border-radius: 4px
    background-color: $recall-code-background
    font-size: 14px
    cursor: pointer

    &:hover
      background-color: mix($recall-code-background, black, 90%)

    i
      vertical-align: super

  .keyboard
    margin-left: 8px

.intro
  display: flex
  margin-bottom: 92px

  .introItem
    padding: 16px
    margin: 0
    font-size: 14px
    line-height: 24px
    text-align: center
    color: $recall-text-light

.cards
  display: grid
  gap: 12px

@media screen and (min-width: 1350px)
  .cards
    gap: 16px
    grid-template-columns: repeat(3, 1fr)

    &.onlyOneItem
      grid-template-columns: repeat(1, 1fr)
      width: 50%
      margin: 0 auto

    &.haveTwoItems
      grid-template-columns: repeat(2, 1fr)
      width: 70%
      margin: 0 auto
  
@media screen and (min-width: 951px) and (max-width: 1349px)
  .cards
    grid-template-columns: repeat(2, 1fr)

    &.onlyOneItem
      grid-template-columns: repeat(1, 1fr)

@media screen and (min-width: 770px) and (max-width: 950px)
  .cards
    grid-template-columns: 1fr

@media screen and (min-width: 688px) and (max-width: 769px)
  .cards
    grid-template-columns: repeat(2, 1fr)

    &.onlyOneItem
      grid-template-columns: repeat(1, 1fr)

@media screen and (max-width: 687px)
  .cards
    grid-template-columns: 1fr

