@import './_all_vars.sass'

:root
  --manage-access-modal-nomic-text-color: #{$recall-manage-access-text-modal}
  --manage-access-modal-nomic-text-color-secondary: #{$recall-manage-access-text-modal-secondary-darkmode}
  --manage-access-button-background: #{$recall-manage-access-button-background}
  --manage-access-property-no-data-text-color: #{$recall-manage-access-label-text-color}
  --manage-access-property-anchor-text-color: #{$recall-manage-access-button-color-darkmode}
  --manage-access-notify-text-color: #{$recall-manage-access-notify-link-color}
  --manage-access-manage-modal-icon-color: #{$secondary--text--colour}
  --manage-access-modal-info-icon-color: #{$recall-manage-access-button-background}
  --manange-access-text-required-color: #{$recall-text-required-color}
  --manage-access-checkbox-color: #{$recall-cra-filter-checkbox}

div:global(.pageThemeDark)
  --manage-access-modal-nomic-text-color: #{$secondary--text--colour}
  --manage-access-modal-nomic-text-color-secondary: #{$recall-manage-access-text-modal-secondary-darkmode}
  --manage-access-button-background: #{$recall-manage-access-button-background-darkmode}
  --manage-access-property-no-data-text-color: #{$recall-manage-access-label-text-color-darkmode}
  --manage-access-property-anchor-text-color: #{$recall-manage-access-button-color}
  --manage-access-notify-text-color: #{$recall-manage-access-notify-link-color-darkmode}
  --manage-access-manage-modal-icon-color: #{$primary--text--colour}
  --manage-access-modal-info-icon-color: #{$recall-manage-access-button-background-darkmode}
  --manange-access-text-required-color: #{$recall-text-required-color}
  --manage-access-checkbox-color: #{$recall-cra-filter-checkbox-darkmode}

.nomicOrgModal
  div:global(.modal)
    overflow: visible
    overflow-x: clip
  div:global(.modal-content)
    overflow: unset

.loadingWrapper
  display: flex
  width: 100%
  justify-content: center

.manageAccessModal
  div:global(.modal-wrapper.is-small .modal)
    min-width: 380px

.contentWrapperNomicModal
  h2
    font-size: 32px
    text-align: left
    color: var(--manage-access-modal-nomic-text-color)
  
  input, textarea
    background-color: transparent !important

  .propertyDescriptionManageAccess
    color: var(--manage-access-modal-nomic-text-color)
    font-weight: 400

  .propertyDescriptionNomicModal
    color: var(--manage-access-modal-nomic-text-color-secondary)
    font-weight: 400

    a
      color: var(--manage-access-property-anchor-text-color)

  .typeDropdownHolder
    flex-direction: column

    .dropdownError
      margin-top: 8px
      font-style: normal
      font-weight: normal
      font-size: 12px
      line-height: 18px
      color: red


  .notificationDescription
    color: var(--manage-access-modal-nomic-text-color)
    font-weight: 400
    
    a:global(.notify-url)
      color: var(--manage-access-notify-text-color)

  .typeWrapper
    display: flex
    width: 100%

    .checkboxWrapper
      width: 25%
      font-size: 14px
      padding-left: 20px

      label
        margin-top: 18px
      
      b
        font-weight: 500
        
    .dropdownWrapper
      width: 75%
      .type
        margin-top: 10px

  .infoRowNomicModal
    display: flex
    flex-wrap: nowrap
    align-items: center
    color: var(--manage-access-modal-nomic-text-color-secondary)

    i
      bottom: 5px

    span
      margin-left: 8px
      font-size: 16px
      font-weight: 400
      width: 90%

  .instructions
    display: flex
    align-items: center
    gap: 16px
    justify-content: center

    i
      color: var(--manage-access-property-no-data-text-color)
  
  .revokeBtn
    border: unset !important

    i
      color: var(--manage-access-modal-nomic-text-color) !important
    &:hover
      background: var(--manage-access-button-background) !important
      i
        color: var(--manage-access-manage-modal-icon-color) !important
  
  .dropdownCustomItem
    color: $recall-manage-access-dropdown-custom-item-color

  .settingsCheckbox
    align-items: left

    div:global(.checkbox)
      border-color: var(--manage-access-checkbox-color) !important
      &:hover
        background: var(--manage-access-checkbox-color) !important

    div:global(.is-checked)  
      border-color: var(--manage-access-checkbox-color) !important
      background: var(--manage-access-checkbox-color) !important
  
  .labelContainer
    margin-bottom: 12px
    .labelWithIcon
      font-family: Arial, Helvetica, sans-serif
      font-weight: bold
      font-size: 14px
      line-height: 22px
    
    .inputRequired
      color: var(--manange-access-text-required-color)
      margin-left: 8px
      margin-top: 4px

    i
      color: var(--manage-access-modal-info-icon-color)
      margin-left: 8px
      cursor: pointer

  .nomicFieldsDropdown
    width: 100%

    div:global(.checkbox)
      border-color: var(--manage-access-checkbox-color) !important
      &:hover
        background: var(--manage-access-checkbox-color) !important

    div:global(.is-indeterminate),
    div:global(.is-checked)  
      border-color: var(--manage-access-checkbox-color) !important
      background: var(--manage-access-checkbox-color) !important


      

