@import './themes/theme_default';
@import './themes/theme_nsw';
@import './my_vars';
@import './nsw_color_vars';

// Add an OR condition after map-get to avoid empty results when a key is missing

// You can also add a specific color for a variable rather than using the $default-color.
// Example: --primary-color: #{map-get($theme, primary-light) or #000000};

@mixin apply-theme($theme, $default-color) {
  div#root {
    --primary-color: #{map-get($theme, primary-light) or $default-color};
    --primary-hover-color: #{map-get($theme, primary-hover-light) or $default-color};

    //pagination
    --pagination-main-dark: #{map-get($theme, pagination, button-bg-hover-light) or $default-color};
    --pagination-main-light: #{map-get($theme, pagination, button-text-light) or $default-color};
    --pagination-main-colour: #{map-get($theme, pagination, button-text-hover-light) or $default-color};
    --pagination-button-background-colour: #{map-get($theme, pagination, active-button-light) or $default-color};

    //input field
    --input-field-clear-icon: #{map-get($theme, input-field, clear-icon-light) or $default-color};
    --input-field-border: #{map-get($theme, input-field, border-light) or $default-color};

    //loader
    --loader-circle: #{map-get($theme, loader, circle-light) or $default-color};
    --loader-label: #{map-get($theme, loader, label-light) or $default-color};

    //dropdown
    --dropdown-selected-text: #{map-get($theme, dropdown, selected, text-light) or $default-color};
    --dropdown-selected-text-hover: #{map-get($theme, dropdown, selected, text-hover-light) or $default-color};
    --dropdown-selected-text-open: #{map-get($theme, dropdown, selected, text-open-light) or $default-color};

    --dropdown-selected-icon: #{map-get($theme, dropdown, selected, icon-light) or $default-color};
    --dropdown-selected-icon-hover: #{map-get($theme, dropdown, selected, icon-hover-light) or $default-color};

    --dropdown-selected-background: #{map-get($theme, dropdown, selected, background-light) or $default-color};
    --dropdown-selected-background-hover: #{map-get($theme, dropdown, selected, background-hover-light) or
      $default-color};
    --dropdown-selected-background-open: #{map-get($theme, dropdown, selected, background-open-light) or $default-color};

    --dropdown-options-background: #{map-get($theme, dropdown, options, background-light) or $default-color};

    --dropdown-item-text-hover: #{map-get($theme, dropdown, item, text-hover-light) or $default-color};
    --dropdown-item-background-hover: #{map-get($theme, dropdown, item, background-hover-light) or $default-color};

    //fileupload
    --file-upload-primary-colour: #{map-get($theme, file-upload, primary-light) or $default-color};
    --file-upload-primary-colour-text: #{map-get($theme, file-upload, text-light) or $default-color};
    --file-upload-button-colour: #{map-get($theme, file-upload, button-light) or $default-color};
    --file-upload-button-hover-background: #{map-get($theme, file-upload, button-hover-light) or $default-color};

    //Modal
    --modal-close-icon: #{map-get($theme, modal, close-icon-light) or $default-color};
    --modal-close-icon-hover: #{map-get($theme, modal, close-icon-hover-light) or $default-color};

    //toggle
    --toggle-switch-primary: #{map-get($theme, toggle, switch-light) or $default-color};

    //Combobox
    --combobox-primary-text-colour: #{map-get($theme, combobox, primary-text-colour-light) or $default-color};
    --combobox-primary-text-colour-hover: #{map-get($theme, combobox, primary-text-colour-hover-light) or $default-color};
    --combobox-main-background-colour: #{map-get($theme, combobox, main-background-colour-light) or $default-color};
    --combobox-disabled-background-colour: #{map-get($theme, combobox, disabled-background-colour-light) or
      $default-color};
    --combobox-item-list-background-colour: #{map-get($theme, combobox, item-list-background-colour-light) or
      $default-color};
    --combobox-item-background-colour: #{map-get($theme, combobox, item-background-colour-light) or $default-color};
    --combobox-options-background-color: #{map-get($theme, combobox, options-background-color-light) or $default-color};
    --combobox-options-background-color-hover: #{map-get($theme, combobox, options-background-color-hover-light) or
      $default-color};
    --combobox-options-border-color: #{map-get($theme, combobox, options-border-color-light) or $default-color};
    --combobox-input-background-color-hover: #{map-get($theme, combobox, input-background-color-hover-light) or
      $default-color};
    --combobox-input-background-open: #{map-get($theme, combobox, input-background-open-light) or $default-color};

    //button
    --button-text: #{map-get($theme, button, text-light) or $default-color};
    --button-background: #{map-get($theme, button, background-light) or $default-color};
    --button-background-hover: #{map-get($theme, button, background-hover-light) or $default-color};

    --button-secondary-text: #{map-get($theme, button, text-secondary-light) or $default-color};
    --button-secondary-text-hover: #{map-get($theme, button, text-secondary-hover-light) or $default-color};

    //checkbox
    --checkbox-checked-background: #{map-get($theme, checkbox, checked-background-light) or $default-color};
    --checkbox-checked-background-hover: #{map-get($theme, checkbox, checked-background-hover-light) or $default-color};
    --checkbox-checked-icon: #{map-get($theme, checkbox, checked-icon-light) or $default-color};

    //chat
    --chat-question-text: #{map-get($theme, chat, question-text-light) or $default-color};
    --chat-question-action: #{map-get($theme, chat, question-action-light) or $default-color};
    --chat-answer: #{map-get($theme, chat, answer-action-light) or $default-color};
    --chat-answer-hover: #{map-get($theme, chat, answer-action-hover-light) or $default-color};
    --chat-answer-action-icon: #{map-get($theme, chat, answer-answer-action-icon-light) or $default-color};
    --chat-related-question-border: #{map-get($theme, chat, related-question-border-light) or $default-color};

    //sidebar
    --sidebar-text-color: #{map-get($theme, sidebar, text-color-light) or $default-color};

    //home page
    --home-page-slide-dot: #{map-get($theme, home-page, slide-dot-light) or $default-color};
    --home-page-heading: #{map-get($theme, home-page, page-heading-bg-light) or $default-color};
    --home-page-slide-bg: #{map-get($theme, home-page, slide-bg) or $default-color};

    //History page
    --history-page-question-text: #{map-get($theme, history-page, question-text-light) or $default-color};
    --history-page-question-text-hover: #{map-get($theme, history-page, question-text-hover-light) or $default-color};
    --history-page-icon: #{map-get($theme, history-page, icon-light) or $default-color};

    //Custom recall app page
    --cra-page-link: #{map-get($theme, cra-page, link-light) or $default-color};

    // Dashboard page
    --dashboard-chat-feedback-text-colour: #{map-get($theme, dashboard, chat-feedback-text-light) or $default-color};

    // Terms And Conditions page
    --terms-conditions-title-link-colour: #{map-get($theme, terms-conditions, title-link-light) or $default-color};
  }

  .pageThemeDark {
    --primary-color: #{map-get($theme, primary-dark) or $default-color};
    --primary-hover-color: #{map-get($theme, primary-hover-dark) or $default-color};

    //pagination
    --pagination-main-dark: #{map-get($theme, pagination, button-bg-hover-dark) or $default-color};
    --pagination-main-light: #{map-get($theme, pagination, button-text-dark) or $default-color};
    --pagination-main-colour: #{map-get($theme, pagination, button-text-hover-dark) or $default-color};
    --pagination-button-background-colour: #{map-get($theme, pagination, active-button-dark) or $default-color};

    //input field
    --input-field-clear-icon: #{map-get($theme, input-field, clear-icon-dark) or $default-color};
    --input-field-border: #{map-get($theme, input-field, border-dark) or $default-color};

    //loader
    --loader-circle: #{map-get($theme, loader, circle-dark) or $default-color};
    --loader-label: #{map-get($theme, loader, label-dark) or $default-color};

    //dropdown
    --dropdown-selected-text: #{map-get($theme, dropdown, selected, text-dark) or $default-color};
    --dropdown-selected-text-hover: #{map-get($theme, dropdown, selected, text-hover-dark) or $default-color};
    --dropdown-selected-text-open: #{map-get($theme, dropdown, selected, text-open-dark) or $default-color};

    --dropdown-selected-icon: #{map-get($theme, dropdown, selected, icon-dark) or $default-color};
    --dropdown-selected-icon-hover: #{map-get($theme, dropdown, selected, icon-hover-dark) or $default-color};

    --dropdown-selected-background: #{map-get($theme, dropdown, selected, background-dark) or $default-color};
    --dropdown-selected-background-hover: #{map-get($theme, dropdown, selected, background-hover-dark) or $default-color};
    --dropdown-selected-background-open: #{map-get($theme, dropdown, selected, background-open-dark) or $default-color};

    --dropdown-options-background: #{map-get($theme, dropdown, options, background-dark) or $default-color};

    --dropdown-item-text-hover: #{map-get($theme, dropdown, item, text-hover-dark) or $default-color};
    --dropdown-item-background-hover: #{map-get($theme, dropdown, item, background-hover-dark) or $default-color};

    //fileupload
    --file-upload-primary-colour: #{map-get($theme, file-upload, primary-dark) or $default-color};
    --file-upload-primary-colour-text: #{map-get($theme, file-upload, text-dark) or $default-color};
    --file-upload-button-colour: #{map-get($theme, file-upload, button-dark) or $default-color};
    --file-upload-button-hover-background: #{map-get($theme, file-upload, button-hover-dark) or $default-color};

    //toggle
    --toggle-switch-primary: #{map-get($theme, toggle, switch-dark) or $default-color};

    //Combobox
    --combobox-primary-text-colour: #{map-get($theme, combobox, primary-text-colour-dark) or $default-color};
    --combobox-primary-text-colour-hover: #{map-get($theme, combobox, primary-text-colour-hover-dark) or $default-color};
    --combobox-main-background-colour: #{map-get($theme, combobox, main-background-colour-dark) or $default-color};
    --combobox-disabled-background-colour: #{map-get($theme, combobox, disabled-background-colour-dark) or
      $default-color};
    --combobox-item-list-background-colour: #{map-get($theme, combobox, item-list-background-colour-dark) or
      $default-color};
    --combobox-item-background-colour: #{map-get($theme, combobox, item-background-colour-dark) or $default-color};
    --combobox-options-background-color: #{map-get($theme, combobox, options-background-color-dark) or $default-color};
    --combobox-options-background-color-hover: #{map-get($theme, combobox, options-background-color-hover-dark) or
      $default-color};
    --combobox-options-border-color: #{map-get($theme, combobox, options-border-color-dark) or $default-color};
    --combobox-input-background-color-hover: #{map-get($theme, combobox, input-background-color-hover-dark) or
      $default-color};
    --combobox-input-background-open: #{map-get($theme, combobox, input-background-open-dark) or $default-color};

    //button
    --button-text: #{map-get($theme, button, text-dark) or $default-color};
    --button-background: #{map-get($theme, button, background-dark) or $default-color};
    --button-background-hover: #{map-get($theme, button, background-hover-dark) or $default-color};

    --button-secondary-text: #{map-get($theme, button, text-secondary-dark) or $default-color};
    --button-secondary-text-hover: #{map-get($theme, button, text-secondary-hover-dark) or $default-color};

    //file upload
    --checkbox-checked-background: #{map-get($theme, checkbox, checked-background-dark) or $default-color};
    --checkbox-checked-background-hover: #{map-get($theme, checkbox, checked-background-hover-dark) or $default-color};
    --checkbox-checked-icon: #{map-get($theme, checkbox, checked-icon-dark) or $default-color};

    //Modal
    --modal-close-icon: #{map-get($theme, modal, close-icon-dark) or $default-color};
    --modal-close-icon-hover: #{map-get($theme, modal, close-icon-hover-dark) or $default-color};

    //chat
    --chat-question-text: #{map-get($theme, chat, question-text-dark) or $default-color};
    --chat-question-action: #{map-get($theme, chat, question-action-dark) or $default-color};
    --chat-answer: #{map-get($theme, chat, answer-action-dark) or $default-color};
    --chat-answer-hover: #{map-get($theme, chat, answer-action-hover-dark) or $default-color};
    --chat-answer-action-icon: #{map-get($theme, chat, answer-answer-action-icon-dark) or $default-color};
    --chat-related-question-border: #{map-get($theme, chat, related-question-border-dark) or $default-color};

    //sidebar
    --sidebar-text-color: #{map-get($theme, sidebar, text-color-dark) or $default-color};

    //Home page
    --home-page-slide-dot: #{map-get($theme, home-page, slide-dot-dark) or $default-color};
    --home-page-heading: #{map-get($theme, home-page, page-heading-bg-dark) or $default-color};
    --home-page-slide-bg: #{map-get($theme, home-page, slide-bg) or $default-color};

    //History page
    --history-page-question-text: #{map-get($theme, history-page, question-text-dark) or $default-color};
    --history-page-question-text-hover: #{map-get($theme, history-page, question-text-hover-dark) or $default-color};
    --history-page-icon: #{map-get($theme, history-page, icon-dark) or $default-color};

    //Custom recall app page
    --cra-page-link: #{map-get($theme, cra-page, link-dark) or $default-color};

    // Dashboard page
    --dashboard-chat-feedback-text-colour: #{map-get($theme, dashboard, chat-feedback-text-dark) or $default-color};

    // Terms And Conditions page
    --terms-conditions-title-link-colour: #{map-get($theme, terms-conditions, title-link-dark) or $default-color};
  }
}

@include apply-theme($theme-default, $recall-primary);

html[client-theme='theme-nsw'] {
  @include apply-theme($theme-nsw, $nsw-primary-light);
}
