@import './_all_vars.sass'

:root
  --app-slide-text-colour: #{$recall-text-menu}
  --app-slide-edited-text: #{$recall-text-menu}
  --app-slide-file-uploader-text: #{$recall-primary}
  --app-slide-separator: #{$recall-menu-background}
  --app-slide-icon: #{$primary--text--colour--lighter}
  --app-slide-delete-icon-hover: #{$recall-slides-delete-btn-hover}
  --app-slide-view-text-colour: #{$recall-homepage-text-lightmode}
  --app-slide-view-background-colour: #{$recall-primary-white}
  --app-slide-view-main-text-colour: #{$recall-primary-white}
  --app-slide-view-main-background-colour: #{$recall-homepage-main-darkmode}
  --app-slide-selected-dot-colour: #{$recall-carousel-dot-selected}
  --app-slide-unselected-dot-colour: #{$recall-carousel-dot-unselected}
  --app-mini-cards-text-colour: #{$recall-posts-description-text}
  --app-mini-cards-background-colour: #{$recall-primary-white}
  --app-mini-cards-border-colour: #{$recall-slides-cards-border-colour}

div:global(.pageThemeDark)
  --app-slide-text-colour: #{$recall-green-500-darkmode}
  --app-slide-edited-text: #{$recall-primary-white}
  --app-slide-file-uploader-text: #{$recall-green-500-darkmode}
  --app-slide-separator: #{$recall-menu-background-darkmode}
  --app-slide-icon: #{$recall-green-500-darkmode}
  --app-slide-delete-icon-hover: #{$recall-slides-delete-btn-hover-darkmode}
  --app-slide-view-text-colour: #{$recall-primary-white}
  --app-slide-view-background-colour: #{$recall-menu-background-darkmode}
  --app-slide-view-main-text-colour: #{$recall-primary-white}
  --app-slide-view-main-background-colour: #{$recall-homepage-main-darkmode}
  --app-slide-selected-dot-colour: #{$recall-carousel-dot-selected-darkmode}
  --app-slide-unselected-dot-colour: #{$recall-carousel-dot-unselected-darkmode}
  --app-mini-cards-text-colour: #{$recall-primary-text-colour-darkmode}
  --app-mini-cards-background-colour: #{$recall-menu-background-darkmode}
  --app-mini-cards-border-colour: #{$recall-menu-background-darkmode}


.toggleLearnMore
  color: var(--app-slide-edited-text) !important


.slideFormInput
  &.nsw
    > label
      input
        border-radius: 4px
        border: 1px solid var(--input-field-border) !important

  > label
      input
        border-bottom: 1px solid var(--input-field-border) !important

.appSlideHolder
  display: flex
  flex-direction: column
  border-radius: 24px
  border: 1px solid var(--app-mini-cards-border-colour)
  background-color: var(--app-slide-view-background-colour)
  padding: 16px
  flex: 1 1

  .appSlide
    display: flex
    padding: 16px 0
    margin-top: 16px
    color: var(--app-slide-text-colour)
    flex-direction: column-reverse
    gap: 16px
    position: relative

    &.main
      flex-direction: row
    
    .slideFormInput label > span:first-child
      color: var(--app-slide-edited-text) !important
    
    .slideFormInput 
      label 
        .input-field 
          input
            border-bottom: 1px solid red !important

    .image
      flex: 1 1
      display: flex
      overflow: hidden
      position: relative
      align-items: flex-start

      img
        display: flex
        flex-direction: column
        align-items: center
        overflow: auto
        position: absolute
        max-width: 100%
        max-height: 300px
      div:global(.loader-wrapper)
        display: flex
        flex: 1 1
        flex-direction: column

    .dots
      display: flex
      position: absolute
      left: 50%
      top: 375px
      transform: translateX(-50%)
      gap: 8px
      cursor: pointer

      .selected
        color: var(--home-page-slide-dot)
      
      .unselected
        color: var(--app-slide-unselected-dot-colour)

    .details
      flex: 1 1

    .slidesArrowRight,
    .slidesArrowLeft
      position: absolute
      cursor: pointer
      top: 50%
      i
        color: $recall-primary-white !important

    .slidesArrowLeft
      left: 8px

    .slidesArrowRight
      right: 8px

  &.viewer
    height: 250px
    margin: 0px 0px 16px 0px
    padding: 0px
    border: none
    .appSlide.main
      margin: 0px
      padding: 0px
      border-radius: 24px
      height: 400px
      gap: 0px
      .push
        flex: 1 1
        background-position: center
        background-size: cover
        background-repeat: no-repeat
        border-top-left-radius: 24px
        border-bottom-left-radius: 24px

      .details
        padding: 12px
        border-top-right-radius: 20px
        border-bottom-right-radius: 20px
        background: var(--home-page-slide-bg)
        flex: unset
        width: 50%

        .form
          margin-top: 20px
          color: var(--app-slide-view-main-text-colour)
          padding-right: 28px
          
          .subTitle
            padding: 4px
            font-size: 13px
            font-weight: 400
            font-variant: small-caps
            letter-spacing: 1px
          .title
            padding: 4px
            margin: 12px 0px
            font-size: 34px
            font-weight: 400
            letter-spacing: 1px
          .description
            padding: 2px 4px
            font-size: 16px
            font-weight: 400
            margin-bottom: 2px
            .descriptionPostsWrapper
              p
                margin: 0
            
          button:global(.button)
            background-color: var(--app-slide-view-main-text-colour)
            color: var(--home-page-slide-bg )
            border-radius: 20px
            margin-right: 16px
    .appSlide.left,
    .appSlide.right
      margin: 0px
      padding: 6px 12px
      border-radius: 24px
      position: relative
      flex-direction: row
      height: 250px
      background-color: var(--app-mini-cards-background-colour)
      border: 1px solid var(--app-mini-cards-border-colour)

      .details
        flex: 1 1
        .form
          margin-top: 20px
          color: var(--app-slide-view-text-colour)
          .subTitle
            padding: 4px
            font-size: 13px
            font-weight: 400
            font-variant: small-caps
            letter-spacing: 0.8px
          .title
            padding: 4px
            margin: 6px 0px
            font-size: 24px
            font-weight: 400
          .description
            padding: 4px
            font-size: 16px
            font-weight: 400
            .descriptionPostsWrapper
              p
                color: var(--app-mini-cards-text-colour)
                margin: 0
                font-size: 14px !important
                line-height: 21px

      .image
        width: 220px
        img
          display: flex
          justify-content: flex-end
          position: absolute
          top: 20px
          right: 6px
          width: 180px
          height: 180px
          border-radius: 100px
          text-align: center
  .appSlideFooter
    display: flex
    align-items: center
    justify-content: space-between

    .deleteSlideBtn
      background-color: unset !important
      color: var(--app-slide-edited-text) !important
      border: 1px solid var(--app-slide-edited-text)
      &:hover
        border: 1px solid var(--app-slide-delete-icon-hover) !important
        i
          color: var(--app-slide-delete-icon-hover) !important

    button:global(.button)
      border-radius: 20px
      margin-right: 16px
      i
        color: unset
      &:hover
        i
          color: white

      &:global(.is-primary)
        color: var(--button-text)
        background-color: var(--button-background)
        border: 1px solod var(--button-background)

        &:hover
          color: var(--button-text)
          background-color: var(--button-background-hover)
          border: 1px solod var(--button-background-hover)

    >div
      display: flex
      align-items: center
      margin-right: 16px
      .pageCount
        display: flex
        align-items: center
        margin-right: 16px
        color: var(--app-slide-edited-text)
        button:global(.button)
          border-radius: 20px
          margin-right: -16px

  .dropzoneLabelContainer
    .dropzoneLabelTextContainer
      color: var(--file-upload-primary-colour-text)
      margin-bottom: 2px

      span
        cursor: pointer
        font-size: 1rem
        font-weight: 700
        text-decoration: underline

@media screen and (min-width: 1400px) and (max-width: 1800px)
  .appSlideHolder
    .appSlide
      .imageFullWidth
        flex: unset

@media screen and (max-width: $breakpoint--desktop-large - 1)
  .appSlideHolder
    &.viewer
      .appSlide.main
        .details
          width: 60%

          .form
            .title
              padding: 4px
              margin: 8px 0px
              font-size: 32px

      .appSlide.left,
      .appSlide.right
        height: 240px
        padding: 8px 18px

@media screen and (max-width: $breakpoint--desktop - 1)
  .appSlideHolder
    &.viewer
      .appSlide.main
        .details
          width: 65%

      .appSlide.left,
      .appSlide.right
        .image
          img
            width: 185px
            height: 185px

@media screen and (min-width: 770px) and (max-width: 810px)
  .appSlideHolder
    &.viewer
      .appSlide.left,
      .appSlide.right
        height: 245px
        padding: 0px 12px

@media screen and (max-width: $breakpoint--tablet - 1)
  .appSlideHolder
    &.viewer
      .appSlide.left,
      .appSlide.right
        height: 240px
        padding: 0px 16px

@media screen and (max-width: $breakpoint--mobile - 1)
  .appSlideHolder
    background-color: var(--home-page-slide-bg)
    &.viewer
      &.mobileView
        .appSlide.main
          position: relative
          .dots
            z-index: 3
          .push
            position: absolute
            top: 0
            left: 0
            width: 42%
            height: 100%
            background-size: cover
            background-position: center
            z-index: 1
            opacity: 0.6
          .details
            width: 100%
            position: relative
            z-index: 2
            padding: 4px 22px
            background: rgba(0, 0, 0, 0)
            border-radius: 24px

      .appSlide.left,
      .appSlide.right
        height: 200px
        padding: 0px 14px

        .details
          padding-right: 18px

        .image
          display: none
    .appSlideFooter
      display: flex
      flex-direction: column
      gap: 16px
      align-items: flex-start
      margin-top: 16px
  .appSlideHolderMobile
    background-color: var(--app-slide-view-background-colour)
    
  .slidesArrowRight,
  .slidesArrowLeft
    display: none

@media screen and (max-width: 415px)
  .appSlideHolder
    &.viewer
      &.mobileView
        .appSlide.main
          position: relative
          height: 430px
          .dots
            position: absolute
            top: 410px     
          .description
            .descriptionPostsWrapper
              p
                font-size: 14px
          .details
            width: 100%
            border-radius: 24px
            padding: 0px 24px

      .appSlide.left,
      .appSlide.right
        height: 220px
        padding: 0px 14px