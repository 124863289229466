@import './_all_vars.sass'

:root
  --system-banner-background-colour: #{$recall-primary-white}
  --system-banner-border-colour: #{$recall-slides-cards-border-colour}
  --system-banner-title-colour: #{$recall-text-menu}
  --system-banner-cancel-btn-colour: #{$recall-text-menu}
  --system-banner-cancel-btn-background-colour: #{$recall-text-menu}
  --system-banner-cancel-btn-colour-hover: #{$recall-primary-white}
  --system-banner-cancel-btn-background-colour-hover: #{$recall-text-menu}

div:global(.pageThemeDark)
  --system-banner-background-colour: #{$recall-menu-background-darkmode}
  --system-banner-border-colour: #{$recall-menu-background-darkmode}
  --system-banner-title-colour: #{$recall-primary-white}
  --system-banner-cancel-btn-colour: #{$recall-primary-white}
  --system-banner-cancel-btn-background-colour: #{$recall-text-menu}
  --system-banner-cancel-btn-colour-hover: #{$recall-text-menu}
  --system-banner-cancel-btn-background-colour-hover: #{$recall-primary-white}

.title
  text-align: start !important
  font-size: 24px !important
  color: var(--system-banner-title-colour) !important

.systemBanner
  display: flex
  flex-direction: column
  border-radius: 20px
  border: 1px solid var(--system-banner-border-colour)
  background-color: var(--system-banner-background-colour)
  padding: 48px 32px
  gap: 48px
  flex: 1 1

  .toggleArea
    display: flex
    justify-content: space-between

    .toggle
      flex-direction: row
      gap: 16px
      align-items: center
      span:global(.right-label)
        color: var(--system-banner-title-colour) !important
      label:global(.toggle-label)
        margin-bottom: unset
        color: var(--system-banner-title-colour) !important

.footer
  margin-top: 16px
  display: flex
  flex-direction: row
  align-items: center
  justify-content: flex-end

  button:global(.button)
    border-radius: 20px
    margin-right: 16px

    &:global(.is-primary), &:global(.is-primary:hover:enabled:not(.is-loading))
      color: var(--button-text)
      background-color: var(--button-background)
      border: 1px solod var(--button-background)

      &:hover
        color: var(--button-text)
        background-color: var(--button-background-hover)
        border: 1px solod var(--button-background-hover)

  .cancelEditBanner
    background-color: unset !important
    color: var(--system-banner-cancel-btn-colour) !important
    border: 1px solid var(--system-banner-cancel-btn-colour) !important

    &:hover
      background-color: var(--system-banner-cancel-btn-background-colour-hover) !important
      border: 1px solidvar(--system-banner-cancel-btn-background-colour-hover) !important
      color: var(--system-banner-cancel-btn-colour-hover) !important
