@import './_all_vars.sass'

:root
  --chat-feedback-text-colour: #{$recall-main-dark}
  --chat-feedback-separator: #{$recall-separator}

div:global(.pageThemeDark)
  --chat-feedback-text-colour: #{$recall-green-300-darkmode}
  --chat-feedback-separator: #{$recall-separator-darkmode}


.feedback
  display: flex
  flex-direction: row
  border-bottom: 1px solid var(--chat-feedback-separator)
  padding: 16px 0
  margin-top: 16px
  margin-bottom: 16px
  margin-right: 16px
  font-size: 14px

  .left
    flex: 0
    align-self: center
    margin-right: 24px
    padding-top: 4px
    align-self: flex-start

  .right
    flex: 1
    gap: 12px
    display: flex
    flex-direction: column
    padding: 0px 12px 12px 0
    overflow: hidden

    .question
      @include lato-regular
      font-size: 16px
      color: var(--dashboard-chat-feedback-text-colour)
      gap: 8px
      display: flex
      flex-wrap: wrap
      align-items: center

    .details
      display: flex
      gap: 12px

    .collapsed
      display: flex
      @include lato-light

      .text
        font-size: 16px
        flex: 1
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        padding-right: 12px

      .more
        font-size: 14px
        flex: 0
        font-weight: bold
        color:  var(--dashboard-chat-feedback-text-colour)
        cursor: pointer

        &:hover
          text-decoration: underline

    .expanded
      display: flex
      @include lato-light

      .text
        font-size: 16px
        flex: 1
        padding-right: 12px

      .more
        font-size: 14px
        flex: 0
        font-weight: bold
        color:  var(--dashboard-chat-feedback-text-colour)
        cursor: pointer

        &:hover
          text-decoration: underline

    .date
      @include lato-light
      font-size: 16px

    .link
      @include lato-regular
      flex: 0
      font-size: 14px
      font-weight: bold
      color:  var(--dashboard-chat-feedback-text-colour)
      cursor: pointer

      &:hover
        text-decoration: underline

@media screen and (max-width: $breakpoint--tablet + 1)
  .feedback
    .left
      margin-right: 8px
