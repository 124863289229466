@import './_all_vars.sass'

:root
  --homePage-dot-carousel: #{$recall-main-dark}
  --chatRecall-question-background-colour: #{$recall-code-background}
  --chatRecall-question-note-colour: #{$recall-text-lighter}
  --chatRecall-question-text-colour: #{$recall-primary-black}
  $chatRecall-question-hover-mix: mix($recall-code-background, black, 90%)
  --chatRecall-question-hover-mix: #{$chatRecall-question-hover-mix}
  --chatCustomApp-map-link-button-colour: #{$recall-primary-black}
  --chatCustomApp-map-link-button-background: #{$recall-cra-button-background}
  --chatCustomApp-map-link-button-background-hover: #{$recall-cra-chat-hover}

div:global(.pageThemeDark)
  --homePage-dot-carousel: #{$recall-green-300-darkmode}
  --chatRecall-question-background-colour: #{$recall-menu-background-darkmode}
  --chatRecall-question-note-colour: #{$recall-primary-white}
  --chatRecall-question-text-colour: #{$recall-primary-white}
  $chatRecall-question-hover-mix-darkmode: mix($recall-menu-background-hover-darkmode, black, 90%)
  --chatRecall-question-hover-mix: #{$chatRecall-question-hover-mix-darkmode}
  --chatCustomApp-map-link-button-colour: #{$recall-primary-black}
  --chatCustomApp-map-link-button-background: #{$recall-cra-button-background}
  --chatCustomApp-map-link-button-background-hover: #{$recall-cra-chat-hover}

h1
  line-height: 1

p
  font-size: 14px

  &.note
    color: var(--chatRecall-question-note-colour)
    font-style: italic

  a
    font-family: inherit

.slides
  display: flex
  flex-direction: column
  .main
    display: flex
    flex-direction: column

.small
  display: flex
  margin-top: 0px
  color: var(--home-admin-text-colour)
  flex-direction: row
  gap: 16px

.chatTitle
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.examples
  margin: 2rem 0
  text-align: left
  gap: 8px
  display: flex
  flex-direction: column

  h3
    margin: 0

  .question
    width: 100%
    padding: 8px
    border-radius: 4px
    background-color: var(--chatRecall-question-background-colour)
    font-size: 14px
    cursor: pointer
    font-family: inherit
    color: var(--chatRecall-question-text-colour)

    &:hover
      background-color: var(--chatRecall-question-hover-mix)

    i
      vertical-align: super

  .tip
    margin-left: 8px

.intro
  display: flex
  margin-bottom: 92px

  .introItem
    padding: 16px
    margin: 0
    font-size: 14px
    line-height: 24px
    text-align: center
    color: $recall-text-light

.tiles
  display: flex
  gap: 12px
  display: grid
  grid-template-columns: repeat(3, 1fr)

  &.onlyOneItem
      grid-template-columns: repeat(1, 1fr)

  &.haveTwoItems
    grid-template-columns: repeat(2, 1fr)


  div:global(.splide)
    width: 100%
    padding: 24px 0

  button:global(.splide__pagination__page)
    height: 7px
    width: 7px

  button:global(.splide__pagination__page.is-active)
    background: var(--homePage-dot-carousel)

.pills
  display: flex
  flex-wrap: wrap
  flex-direction: column
  align-items: center
  text-align: center
  gap: 8px

.shortcuts
  display: flex
  gap: 12px
  flex-wrap: wrap
  align-self: center

.divider
  width: 100%
  margin-top: 3rem
  border: 1px solid $recall-separator

.centerHolder
  text-align: center

  .craNavIcon
    i
      color: $recall-cra-chat

  .craAnchor
    color: inherit

.craAppSwitcherContainer
  position: fixed
  top: 9px
  right: 258px
  display: flex
  align-items: center
  z-index: 100
  height: 44px

  .mapLinkButton
    height: 100%
    display: flex
    align-items: center
    border-radius: 50%
    border: none !important
    cursor: pointer
    color: var(--chatCustomApp-map-link-button-colour) !important
    background-color: var(--chatCustomApp-map-link-button-background) !important

    &:hover
      background-color: var(--chatCustomApp-map-link-button-background-hover) !important

.appTilesTitle
  letter-spacing: 1.1px
  background: var(--home-page-heading)
  -webkit-background-clip: text 
  -webkit-text-fill-color: transparent
  
.bhpLogo
  height: auto !important
  padding-top: 5px

@media screen and (max-width: $breakpoint--desktop-large - 1)
  .small
    display: flex
    flex-direction: column
    gap: 0px

@media screen and (min-width: $breakpoint--desktop)
  .tiles
    &.onlyOneItem
      width: 50%
      margin: 0 auto
    
@media screen and (min-width: $breakpoint--desktop) and (max-width: $breakpoint--desktop-large)
  .tiles
    grid-template-columns: repeat(2, 1fr)

    &.onlyOneItem
      grid-template-columns: repeat(1, 1fr)
      width: 50%
      margin: 0 auto

@media screen and (min-width: $breakpoint--tablet) and (max-width: $breakpoint--desktop)
  .tiles
    grid-template-columns: 1fr

@media screen and (min-width: $recall-content-width-desktop) and (max-width: $breakpoint--tablet)
  .tiles
    grid-template-columns: repeat(2, 1fr)

    &.onlyOneItem
      grid-template-columns: repeat(1, 1fr)

@media screen and (max-width: $breakpoint--mobile)
  .chatTitle
    margin: 36px 0 8px 0 !important

  .tiles
    grid-template-columns: 1fr
