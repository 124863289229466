@import './_all_vars.sass'

:root
  --modal-background-color: #{$recall-homepage-background-lightmode}
  --panel-background-color: #{$recall-cra-button-color}
  --checkbox-background-color: #{$recall-cra-button-background}
  --selected-site-background-color: #{$recall-cra-chat}
  --table-row-icon-color: #{$recall-cra-button-background}
  --recall-item-font-color: #{$recall-text-color}
  --site-item-border-color: #{$recall-cra-table-row-background-hover}
  --table-bottom-border-color: #{$recall-cra-sharepoint-table-border}
  --breadcrumb-font-color: #{$recall-cra-label-text-color}
  --breadcrumb-font-color-hover: #{$recall-cra-button-background}
  --info-container-color: #{$recall-cra-button-background}

div:global(.pageThemeDark)
  --modal-background-color: #{$recall-text-menu}
  --panel-background-color: #{$recall-primary-black-darkmode}
  --checkbox-background-color: #{$recall-cra-chat}
  --selected-site-background-color: #{$recall-cra-button-background}
  --recall-item-font-color: #{$recall-cra-button-color}
  --site-item-border-color: #{$recall-cra-table-row-background-hover}
  --table-bottom-border-color: #{$recall-cra-sharepoint-table-border}
  --breadcrumb-font-color: #{$recall-cra-button-color}
  --breadcrumb-font-color-hover: #{$recall-cra-chat}
  --info-container-color: #{$recall-cra-chat}


.sharePointModal
    div:global(.modal),
    i:global(.modal-close-icon)
        background-color: var(--modal-background-color)

.searchBox
    width: 100% !important

    input
        border-radius: 10px
        border-bottom: none !important

    i
        color: black !important
        font-size: 25px !important

.fileBrowserLayout
    padding-top: 30px
    .mainContent
        display: flex
        flex-direction: column

        .topContainer
            margin: 10px 0

            .breadcrumbContainer
                cursor: pointer

                .breadcrumbItem
                    color: var(--breadcrumb-font-color)
                    font-weight: 400
                    font-size: 16px
                    border-bottom: 1px solid var(--breadcrumb-font-color)
                    margin: 5px

                    &:hover
                        color: var(--breadcrumb-font-color-hover) !important
                        border-bottom: 1px solid var(--breadcrumb-font-color-hover) !important

        .contentContainer
            height: 500px
            overflow-y: hidden

        .sitesPanel
            background: var(--panel-background-color)
            height: 100%
            overflow-y: auto
            border-radius: 10px
            width: 100%
            margin-right: 15px

            .sitesLoader
                height: 100%
                width: 100%
                display: flex
                align-items: center
                justify-content: center

            .selectedSite
                background-color: var(--selected-site-background-color)

            .resultCount
                margin: auto
                padding: 10px 16px
                display: flex
                align-items: center
                justify-content: center
                color: var(--checkbox-background-color)
                font-size: 15px

            .siteItem
                margin: auto
                width: 100%
                display: flex
                align-items: center
                border-bottom: 1px solid var(--site-item-border-color)
                padding: 10px 16px
                cursor: pointer
                color: var(--recall-item-font-color)
                font-weight: 400

                .siteIcon
                    margin: 10px

                .siteLabel
                    font-size: 14px
                    display: block
                    white-space: nowrap
                    overflow: hidden
                    text-overflow: ellipsis
                    width: 350px

        .infoContainer
            color: var(--info-container-color)
            border: 1px solid var(--info-container-color)
            width: 100%
            height: 50px
            margin-bottom: 10px
            padding: 10px
            font-size: 12px
            display: flex
            align-items: flex-start

            .infoIcon
                margin: auto 0

            .infoTextContainer
                display: flex
                flex-direction: column
                margin: 0 10px

        .tableContainer
            width: 100%
            height: 100%

            .selectAllCheckbox
                background: gray
                position: absolute

            .loaderContainer
                position: absolute
                top: 50%
                left: 60%
                transform: translate(-50%, -50%)

        .tableRow
            cursor: pointer !important
            height: 55px !important

        div:global(.table-main-wrapper)
            background-color: var(--modal-background-color) !important
            height: 90%
            overflow-y: auto


            label:global(.checkbox-label)
                margin: auto
                display: block

                div:global(.checkbox)
                    border-color: var(--checkbox-background-color) !important
                    margin: auto !important

                    &:hover
                        background: var(--checkbox-background-color) !important

                div:global(.is-checked)  
                    border-color: var(--checkbox-background-color) !important
                    background: var(--checkbox-background-color) !important
                    color: white !important

            .checkBoxCell
                min-width: 35px !important
                width: 35px !important
                align-items: center
                justify-content: center

            .iconCell
                display: flex
                gap: 8px

                .fileIcon
                    width: 20px

            tr:global(.table-row)
                background-color: var(--modal-background-color) !important

                &:hover
                    background-color: var(--panel-background-color) !important

            td:global(.table-cell)
                background-color: inherit !important
                border-bottom: 1px solid var(--table-bottom-border-color) !important

            thead
                i:global(.table-sort-icon)
                    color: var(--checkbox-background-color) !important
                    font-size: 20px
                    margin: auto 5px
                    font-weight: 400

.breadcrumbs
    margin: auto 0

.buttonContainer
    display: flex
    gap: 8px
    margin: 8px 0

    .actionBtn
        border-radius: 24px
