@import './_all_vars.sass'

:root
  --answer-actions-button-colour: #{$primary--text--colour}
  --answer-actions-button-colour-hover: #{$recall-main-dark}
  --answer-actions-background-colour-hover: #{$recall-menu-background}

div:global(.pageThemeDark)
  --answer-actions-button-colour: #{$recall-green-500-darkmode}
  --answer-actions-button-colour-hover: #{$recall-green-200-darkmode}
  --answer-actions-background-colour-hover: #{$recall-menu-background-hover-darkmode}

.actions
  text-align: left
  padding: 16px 0 16px

  .infoPopup
    text-align: left

    p
      line-height: 1
      padding: 0
      margin: 0
      font-size: 14px !important


  .actionButton:enabled
    background-color: transparent !important
    border: 0px
    color: var(--chat-answer) !important

    &:hover:enabled:not(.is-loading),
    &:focus:enabled:not(.is-loading)
      background-color: var(--answer-actions-background-colour-hover) !important
      border: 0px
      color: var(--chat-answer-hover) !important


  .actionButton:disabled
    background-color: transparent !important
    border: 0px

    &:hover:disabled:not(.is-loading),
    &:focus:disabled:not(.is-loading)
      background-color: var(--answer-actions-background-colour-hover) !important
      border: 0px
