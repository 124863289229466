$recall-main-darker: #006358;
$recall-main-dark: #00796b;
$recall-main-light: #068576;
$recall-status-warning: #da3b20;
$recall-status-success: #00a72f;
$recall-status-info: #2064da;
$recall-dropdown-background: #fafafa;
$recall-dropdown-option-background: #b2dfdb;

$recall-heading: #373a36;
$recall-text-color: #373a36;
$recall-text-menu: #373a36;
$recall-text-menu-background-hover: #dee1dd;
$recall-text-light: #666;
$recall-text-required-color: #e0452a;
$recall-message-text-light: #555;
$recall-text-lighter: #919191;
$recall-text-disabled: #888;
$recal-input-primary-text-colour: #696c67;
$recall-posts-description-text: #373a36;
$recall-slides-cards-border-colour: #efefef;
$recall-slides-delete-btn-hover: #000;
$recall-slides-primary-white: #fff;
$recall-slides-primary-black: #373a36;

$recall-code-background: #eee;
$recall-file-drop-background: #eee;
$recall-file-drop-color: #aaa;
$recall-file-upload-icon-color: #dbdbda;

$recall-app-tile-border: #eaeaea;

$recall-app-card-border: #eaeaea;
$recall-menu-background: #eee;
$recall-menu-active: #ddd;
$recall-separator: #cdcdcc;
$recall-input-field-background-colour: #efefef;
$recall-input-field-icon-colour: #8e9c9c;

$recall-primary: #00796b;
$recall-primary-hover-light: #004d40;
$recall-primary-dark: #85c446;
$recall-primary-hover-dark: #b8df7c;

$recall-cra-chat: #8fe2ff;
$recall-cra-chat-hover: #8fe1ffcc;
$recall-cra-chat-color: #0a192f;

$recall-cra-header-text: #aeb1b7;
$recall-cra-divider: #efefef;
$recall-cra-table-row-background-hover: #efefef;
$recall-cra-button-background: #1692bf;
$recall-cra-button-border: #1692bf;
$recall-cra-button-background-hover: #373a36;
$recall-cra-button-text-color: #1b1b1b;
$recall-cra-dropdown-background-color: #1b1b1b;
$recall-cra-dropdown-background-color-hover: #8fe2ff;
$recall-cra-label-text-color: #000000;
$recall-cra-button-text-disabled: #91948f;
$recall-cra-button-color: #ffffff;
$recall-cra-button-secondary-color: #000000;
$recall-cra-button-icon-color: #000000;
$recall-cra-dropdown-selected-border-hover: #1692bf;
$recall-cra-form-input-focus-color: #1692bf;
$recall-cra-overflowmenu-icon-background: #8fe2ff;
$recall-cra-checkbox-border-color: #000000;
$recall-cra-notify-link-color: #8fe2ff;
$recall-cra-text-modal: #373a36;
$recall-cra-text-modal-secondary: #8e9c9c;

$recall-cra-button-background-darkmode: #8fe2ff;
$recall-cra-button-border-darkmode: #8fe2ff;
$recall-cra-button-background-pressed-darkmode: #ffffff;
$recall-cra-button-background-hover-darkmode: #efefef;
$recall-cra-dropdown-selected-border-hover-darkmode: #e3d2ff;
$recall-cra-label-text-color-darkmode: #8e9c9c;
$recall-cra-button-background-disabled-darkmode: #4d4d4d;
$recall-cra-button-text-disabled-darkmode: #91948f;
$recall-cra-button-color-darkmode: #000000;
$recall-cra-button-secondary-color-darkmode: #ffffff;
$recall-cra-button-icon-color-darkmode: #ffffff;
$recall-button-border-focus-darkmode: #efefef;
$recall-cra-dropdown-selected-border-hover-darkmode: #8fe2ff;
$recall-cra-form-input-focus-color-darkmode: #8fe2ff;
$recall-cra-checkbox-border-color-darkmode: #ffffff;
$recall-cra-notify-link-color-darkmode: #8fe2ff;
$recall-cra-filter-checkbox: #1692bf;
$recall-cra-filter-dropdown-options-border: #91948f;
$recall-cra-overflowmenu-menu-options-hover: #f2f2f2;
$recall-cra-dropdown-options-text-color: #ffffff;

$recall-cra-pill-status-one: #efefef;
$recall-cra-pill-status-two: #c1ea99;
$recall-cra-pill-status-three: #c4f7ea;

$recall-cra-sharepoint-table-border: #e0e0e0;

$recall-bamboo-button-border: #6f49ad;
$recall-bamboo-button-background: #6f49ad;
$recall-bamboo-button-background-pressed: #4e5859;
$recall-bamboo-button-background-hover: #373a36;
$recall-bamboo-button-background-disabled: #e5e9e4;
$recall-bamboo-button-text-disabled: #91948f;
$recall-bamboo-button-color: #ffffff;
$recall-bamboo-button-secondary-color: #000000;
$recall-bamboo-button-icon-color: #000000;
$recall-button-border-focus: #373a36;

//Manage Access Modal
$recall-manage-access-text-modal: #373a36;
$recall-manage-access-text-modal-secondary-darkmode: #8e9c9c;
$recall-manage-access-button-background: #1692bf;
$recall-manage-access-label-text-color: #000000;
$recall-manage-access-button-color-darkmode: #000000;
$recall-manage-access-notify-link-color: #8fe2ff;
$recall-manage-access-button-background-darkmode: #8fe2ff;
$recall-manage-access-label-text-color-darkmode: #8e9c9c;
$recall-manage-access-button-color: #ffffff;
$recall-manage-access-notify-link-color-darkmode: #8fe2ff;
$recall-manage-access-dropdown-custom-item-color: #8fe2ff;

// Winwise
$winwise-button-background: #00796b;
$winwise-button-background-darkmode: #00cdb6;
$winwise-button-background-disabled-darkmode: #e5e9e4;
$winwise-button-background-hover: #004d40;
$winwise-button-background-hover-darkmode: #4cd7c7;
$winwise-button-text-color: #00796b;
$winwise-button-text-disabled-color: #91948f;
$winwise-form-input-focus-color: #00cdb6;
$winwise-form-input-focus-color-darkmode: #00cdb6;
$winwise-file-upload-desc-color: #8e9c9c;
$winwise-file-upload-desc-color-darkmode: #bdcece;

$recall-chat-button-border: #0a192f;
$recall-chat-button-background: #9399a3;
$recall-chat-button-background-hover: #bdbdbd;
$recall-chat-button-secondary-color: #000000;
$recall-chat-button-icon-color: #000000;
$recall-chat-button-border-focus: #373a36;
$recall-chat-dropdown-background: #fff;

$recall-recall-chat: #85c446;
$recall-recall-chat-hover: #43a057;
$recall-recall-chat-color: #0a192f;

$recall-gpt-chat: #f6d510;
$recall-gpt-chat-hover: #aaaa00;
$recall-gpt-chat-color: #0a192f;

$recall-code-chat: #b2e3f4;
$recall-code-chat-disabled: #c4f7ea;
$recall-code-chat-hover: #4387a0;
$recall-code-chat-color: #0a192f;
$recall-code-replaced-text-color: #8e9c9c;

$recall-pm-chat: #e5b776;
$recall-pm-chat-color: #0a192f;

$recall-bamboo-chat: #6f49ad;
$recall-cra: #8fe2ff;
$recall-winwise: #45c5b6;
$recall-bamboo: #ffdfaf;
$recall-vine: #e3d2ff;
$recall-bhp-chat: #f36a22;
$recall-bhp-chat-hover: #fff;
$recall-bhp-chat-color: #fff;
$recall-rio-tinto-chat: #e60d2e;
$recall-ausnet-chat: #4b71c2;
$recall-standard-chats-btn-background-hover: #373a36;
$recall-standard-chats-btn-background-hover-darkmode: #efefef;

$recall-bamboo-header-background: #dbdfdf;
$recall-bamboo-header-selected-file: #373a36;
$recall-bamboo-header-selected-filename: #6f49ad;
$recall-bamboo-header-selected-filepage: #373a36;
$recall-bamboo-header-manage-file-button-background: #fff;
$recall-bamboo-header-manage-file-button-color: #373a36;
$recall-bamboo-header-right-side-background: #ededed;
$recall-bamboo-entity-sidebar-background: #fafafa;
$recall-bamboo-entity-sidebar-title: #373a36;
$recall-bamboo-property-no-data-text-color: #8e9c9c;
$recall-bamboo-file-manager-description-text: #8e9c9c;
$recall-bamboo-home-text-color: #8e9c9c;
$recall-bamboo-form-input-border-color: #373a36;
$recall-bamboo-form-input-focus-color: #6f49ad;
$recall-bamboo-modal-placeholder-color: #8e9c9c;

$recall-bamboo-dropdown-background: #fff;
$recall-bamboo-dropdown-selected-background: #4e5859;
$recall-bamboo-dropdown-selected-text: #ffffff;
$recall-bamboo-dropdown-selected-background-hover: #e3d2ff;
$recall-bamboo-dropdown-selected-border-hover: #6f49ad;
$recall-bamboo-dropdown-selected-background-border: #4e5859;
$recall-bamboo-dropdown-text: #8e9c9c;
$recall-bamboo-dropdown-options-text-color: #000000;
$recall-bamboo-dropdown-text-hover-color: #ffffff;
$recall-bamboo-dropdown-icon: #6f49ad;

$recall-bamboo-checkbox-checked-background: #373a36;
$recall-bamboo-checkbox-hover-background: #6f49ad;
$recall-bamboo-checkbox-icon-color: #ffffff;
$recall-bamboo-close-modal-icon-color: #373a36;
$recall-bamboo-entity-modal-description-color: #8e9c9c;
$recall-bamboo-entity-modal-cancel-background: #fff;
$recall-bamboo-entity-description-color: #8e9c9c;
$recall-bamboo-entity-selected-background: #ffffff;
$recall-bamboo-property-modal-title-color: #171a16;
$recall-bamboo-table-cell-border-bottom: #f2f2ed;

$recall-bamboo-overflowmenu-icon-background: #6f49ad;
$recall-bamboo-overflowmenu-menu-options: #ffffff;
$recall-bamboo-overflowmenu-menu-options-hover: #fafafa;

// WinWise
$recall-winwise-button-background: #00796b;
$recall-winwise-button-background-darkmode: #00cdb6;
$recall-winwise-resize-icon: #ffffff;
$recall-winwise-resize-icon-darkmode: #ffffff;

// Recall darkmode
$recall-chat-button-background-darkmode: #85c446;
$recall-chat-button-border-darkmode: #85c446;
$recall-chat-button-background-pressed-darkmode: #43a057;
$recall-chat-button-background-hover-darkmode: #43a057;
$recall-chat-dropdown-selected-border-hover-darkmode: #43a057;
$recall-chat-label-text-color-darkmode: #8e9c9c;
$recall-chat-button-background-disabled-darkmode: #4d4d4d;
$recall-chat-button-text-disabled-darkmode: #91948f;
$recall-chat-button-color-darkmode: #000000;
$recall-chat-button-secondary-color-darkmode: #ffffff;
$recall-chat-button-icon-color-darkmode: #ffffff;
$recall-chat-dropdown-hover-background-darkmode: #c0de88;
$recall-chat-dropdown-background-darkmode: #919c9c;

//Bamboo darkmode
$recall-bamboo-header-background-darkmode: #4f534d;
$recall-bamboo-header-manage-file-button-background-darkmode: #1b1b1b;
$recall-bamboo-header-manage-file-button-color-darkmode: #aeb1b7;
$recall-bamboo-header-right-side-background-darkmode: #444742;
$recall-bamboo-file-manager-description-text-darkmode: #bdcece;

$recall-bamboo-button-background-darkmode: #c7b3e9;
$recall-bamboo-button-border-darkmode: #c7b3e9;
$recall-bamboo-button-background-pressed-darkmode: #ffffff;
$recall-bamboo-button-background-hover-darkmode: #efefef;
$recall-bamboo-dropdown-selected-border-hover-darkmode: #e3d2ff;
$recall-bamboo-button-background-disabled-darkmode: #4d4d4d;
$recall-bamboo-button-text-disabled-darkmode: #91948f;
$recall-bamboo-button-color-darkmode: #000000;
$recall-bamboo-button-secondary-color-darkmode: #000000;
$recall-bamboo-button-icon-color-darkmode: #ffffff;
$recall-button-border-focus-darkmode: #efefef;

$recall-bamboo-entity-sidebar-background-darkmode: #333333;
$recall-bamboo-header-selected-file-darkmode: #aeb1b7;
$recall-bamboo-header-selected-filename-darkmode: #ffffff;
$recall-bamboo-header-selected-filepage-darkmode: #ffffff;
$recall-bamboo-entity-sidebar-title-darkmode: #ffffff;
$recall-bamboo-property-no-data-text-color-darkmode: #8e9c9c;
$recall-bamboo-form-input-border-color-darkmode: #ffffff;
$recall-bamboo-form-input-focus-color-darkmode: #c7b3e9;
$recall-bamboo-modal-placeholder-color-darkmode: #bdcece;
$recall-bamboo-dropdown-background-darkmode: #e3d2ff;
$recall-bamboo-dropdown-text-darkmode: #bdcece;
$recall-bamboo-dropdown-selected-text-darkmode: #1b1b1b;
$recall-bamboo-dropdown-selected-background-darkmode: #4e5859;
$recall-bamboo-dropdown-selected-background-hover-darkmode: #e3d2ff;
$recall-bamboo-dropdown-selected-background-border-darkmode: #ffffff;
$recall-bamboo-dropdown-options-text-color-darkmode: #000000;
$recall-bamboo-dropdown-text-hover-color-darkmode: #ffffff;
$recall-bamboo-dropdown-icon-darkmode: #e3d2ff;
$recall-bamboo-checkbox-checked-background-darkmode: #ffffff;
$recall-bamboo-checkbox-checked-background-hover-darkmode: #c7b3e9;
$recall-bamboo-checkbox-hover-background-darkmode: #6f49ad;
$recall-bamboo-checkbox-icon-color-darkmode: #373a36;
$recall-bamboo-close-modal-icon-color-darkmode: #373a36;
$recall-bamboo-entity-modal-description-darkmode: #bdcece;
$recall-bamboo-entity-modal-cancel-background-darkmode: transparent;
$recall-bamboo-entity-description-color-darkmode: #bdcece;
$recall-bamboo-home-text-color-darkmode: #bdcece;
$recall-bamboo-chat-darkmode: #c7b3e9;
$recall-bamboo-entity-selected-background-darkmode: #1b1b1b;
$recall-bamboo-table-cell-border-bottom-darkmode: #373a36;
$recall-bamboo-file-upload-text-color-darkmode: #aeb1b7;
$recall-bamboo-overflowmenu-icon-background-darkmode: #c7b3e9;
$recall-bamboo-overflowmenu-menu-options-darkmode: #373a36;
$recall-bamboo-overflowmenu-menu-options-hover-darkmode: #4e5859;

$recall-cra-header-text-darkmode: #ffffff;
$recall-cra-table-row-background-hover-darkmode: #373a36;
$recall-cra-dropdown-background-color-darkmode: #373a36;
$recall-cra-dropdown-background-color-hover-darkmode: #1692bf;
$recall-cra-divider-darkmode: #373a36;
$recall-cra-table-row-border-bottom-darkmode: #373a36;
$recall-cra-filter-checkbox-darkmode: #8fe2ff;
$recall-cra-dropdown-selected-background-darkmode: #4e5859;
$recall-cra-text-modal-secondary-darkmode: #8e9c9c;
$recall-cra-overflowmenu-menu-options-hover-darkmode: #454e4f;
$recall-cra-dropdown-options-text-color-darkmode: #000000;

$recall-cra-pill-status-one-darkmode: #efefef;
$recall-cra-pill-status-two-darkmode: #c1ea99;
$recall-cra-pill-status-three-darkmode: #c4f7ea;

$recall-input-field-background-colour-darkmode: #373a36;
$recall-input-field-icon-colour-darkmode: #bdcece;

$recall-editor-tool-bar-background: #1b4747;
$recall-pagination-background: #263238;

$primary--text--colour: #000;
$tertiary--text--colour: #373a36;
$primary--text--colour--light: $aurecon--grey--800;
$primary--text--colour--lighter: $aurecon--grey--700;
$secondary--text--colour: #fff;
$secondary--text--colour--light: $aurecon--grey--200;
$secondary--text--colour--dark: $aurecon--grey--400;

$recall-pill-info-text: #263238;

$pill--colour--1: #eee;
$pill--colour--2: $recall-recall-chat;
$pill--colour--3: $recall-gpt-chat;
$pill--colour--4: #87e0d5;

$pill--colour--5: #c1ea99;
$pill--colour--6: #26f7df;
$pill--colour--7: #ffbd9a;

//Darkmode Pill Pallete
$pill--colour--16: #373a36;

$recall-menu-width: 275px;
$recall-menu-width-small: 48px;
$recall-menu-logo-width: 175px;
$recall-menu-logo-width-small: 48px;

$recall-content-width-desktop: 650px;
$recall-content-width-desktop-large: 880px;

$breakpoint--mobile: 576px;
$breakpoint--tablet: 768px;
$breakpoint--desktop: 992px;
$breakpoint--desktop-large: 1200px;

$recall--modal--background: rgba(0, 0, 0, 0.9);

$recall-primary-white: #fff;
$recall-primary-black: #000;

$recall-homepage-background-lightmode: #efefef;
$recall-homepage-text-lightmode: $recall-text-menu;

// DARKMODE COLOURS
$recall-text-color-darkmode: #ffffff;
$recall-menu-background-hover-darkmode: #373a36;
$recall-active-background-colour: #4e5859;
$recall-menu-background-darkmode: #373a36;
$recall-primary-black-darkmode: #1b1b1b;
$recall-pagination-items-background-darkmode: #8e9c9c;
$recall-dropdown-background-darkmode: #373a36;
$recall-code-replaced-text-color-darkmode: #bdcece;
$recall-slides-delete-btn-hover-darkmode: #efefef;

$recall-primary-text-colour-darkmode: #aeb1b7;
$recall-heading-darkmode: #aeb1b7;
$recall-question-box-darkmode: #2d302c;
$recall-separator-darkmode: #545556;

$recall-homepage-main-darkmode: #212b2b;

$recall-green-200-darkmode: #b8df7c;
$recall-green-300-darkmode: #85c446;
$recall-green-500-darkmode: #89c925;
$recall-green-600-darkmode: #00bd39;
$recall-green-700-darkmode: #008a15;

$recall-file-preview-background: #dbdfdf;
$recall-file-preview-background-darkmode: #4f534d;

$recall-banner-text-color: #fff;
$recall-banner-text-color-darkmode: #000;

$recall-banner-message-background: #373a36;
$recall-banner-message-background-darkmode: #fff;

$recall-carousel-dot-selected: #85c446;
$recall-carousel-dot-unselected: #ffffff50;

$recall-carousel-dot-selected-darkmode: #85c446;
$recall-carousel-dot-unselected-darkmode: #ffffff50;

$recall-expand-button-background: #ffffff;
$recall-expand-button-background-darkmode: #1b1b1b;
$recall-expand-button-color: #1b1b1b;
$recall-expand-button-color-darkmode: #ffffff;

// Theme Default Variables
$recall-theme-primary-light: #00796b;
$recall-theme-primary-dark: #85c446;
$recall-theme-primary-hover-light: #004d40;
$recall-theme-primary-hover-dark: #b8df7c;

// Pagination
$recall-pagination-button-text-light: #068576;
$recall-pagination-button-text-hover-light: #fff;
$recall-pagination-button-text-dark: #85c446;
$recall-pagination-button-text-hover-dark: #1b1b1b;
$recall-pagination-button-bg-light: #00796b;
$recall-pagination-button-bg-hover-light: #00796b;
$recall-pagination-button-bg-dark: #b8df7c;
$recall-pagination-button-bg-hover-dark: #b8df7c;
$recall-pagination-active-text-light: #fff;
$recall-pagination-active-text-dark: #1b1b1b;
$recall-pagination-active-button-light: #263238;
$recall-pagination-active-button-dark: #89c925;

// Input Field
$recall-input-field-clear-icon-light: #00796b;
$recall-input-field-clear-icon-dark: #85c446;
$recall-input-field-border-light: #00796b;
$recall-input-field-border-dark: #85c446;

// Button
$recall-button-background-light: #00796b;
$recall-button-background-hover-light: #004d40;
$recall-button-background-dark: #85c446;
$recall-button-background-hover-dark: #b8df7c;
$recall-button-text-light: #fff;
$recall-button-text-dark: #1b1b1b;
$recall-button-text-secondary-light: #00796b;
$recall-button-text-secondary-hover-light: #004d40;
$recall-button-text-secondary-dark: #85c446;
$recall-button-text-secondary-hover-dark: #b8df7c;

// Combobox
$recall-combobox-text-light: #000;
$recall-combobox-text-hover-light: #000;
$recall-combobox-background-light: #373a36;
$recall-combobox-disabled-background-light: #fff;
$recall-combobox-list-background-light: #8e9c9c;
$recall-combobox-item-background-light: #b8df7c;
$recall-combobox-options-background-light: #fafafa;
$recall-combobox-options-hover-light: #b2dfdb;
$recall-combobox-options-border-light: #00796b;
$recall-combobox-input-hover-light: #00796b;
$recall-combobox-input-open-light: #1b4747;

$recall-combobox-text-dark: #fff;
$recall-combobox-text-hover-dark: #000;
$recall-combobox-background-dark: #373a36;
$recall-combobox-disabled-background-dark: #373a36;
$recall-combobox-list-background-dark: #8e9c9c;
$recall-combobox-item-background-dark: #b8df7c;
$recall-combobox-options-background-dark: #8e9c9c;
$recall-combobox-options-hover-dark: #b8df7c;
$recall-combobox-options-border-dark: #373a36;
$recall-combobox-input-hover-dark: #373a36;
$recall-combobox-input-open-dark: #373a36;

// Dropdown
$recall-dropdown-text-light: #000;
$recall-dropdown-text-hover-light: #fff;
$recall-dropdown-text-dark: #fff;
$recall-dropdown-text-hover-dark: #fff;
$recall-dropdown-text-open-light: #fff;
$recall-dropdown-text-open-dark: #fff;
$recall-dropdown-icon-light: #1b4747;
$recall-dropdown-icon-hover-light: #fff;
$recall-dropdown-icon-dark: #85c446;
$recall-dropdown-icon-hover-dark: #fff;
$recall-dropdown-background-light: #fafafa;
$recall-dropdown-background-hover-light: #00796b;
$recall-dropdown-background-dark: #373a36;
$recall-dropdown-background-hover-dark: #373a36;
$recall-dropdown-background-open-light: #1b4747;
$recall-dropdown-background-open-dark: #373a36;
$recall-dropdown-options-background-light: #fafafa;
$recall-dropdown-options-background-dark: #8e9c9c;
$recall-dropdown-item-text-hover-light: #000;
$recall-dropdown-item-text-hover-dark: #000;
$recall-dropdown-item-background-hover-light: #b2dfdb;
$recall-dropdown-item-background-hover-dark: #b8df7c;

// File Upload
$recall-file-upload-primary-light: #00796b;
$recall-file-upload-primary-dark: #85c446;
$recall-file-upload-text-light: #000;
$recall-file-upload-text-dark: #fff;
$recall-file-upload-button-light: #fff;
$recall-file-upload-button-hover-light: #373a36;
$recall-file-upload-button-dark: #000;
$recall-file-upload-button-hover-dark: #efefef;

// Loader
$recall-loader-circle-light: #00796b;
$recall-loader-circle-dark: #85c446;
$recall-loader-label-light: #00796b;
$recall-loader-label-dark: #85c446;

// Toggle
$recall-toggle-switch-light: #00796b;
$recall-toggle-switch-dark: #85c446;

// Checkbox
$recall-checkbox-background-light: #00796b;
$recall-checkbox-background-hover-light: #004d40;
$recall-checkbox-background-dark: #85c446;
$recall-checkbox-background-hover-dark: #b8df7c;
$recall-checkbox-icon-light: #fff;
$recall-checkbox-icon-dark: #373a36;

// Sidebar
$recall-sidebar-text-light: #373a36;
$recall-sidebar-text-dark: #aeb1b7;

// Chat
$recall-chat-question-light: #00796b;
$recall-chat-question-dark: #89c925;
$recall-chat-question-action-light: #616161;
$recall-chat-question-action-dark: #89c925;
$recall-chat-answer-action-light: #00796b;
$recall-chat-answer-action-hover-light: #004d40;
$recall-chat-answer-action-dark: #89c925;
$recall-chat-answer-action-hover-dark: #b8df7c;
$recall-chat-border-light: #00796b;
$recall-chat-border-dark: #85c446;
$recall-chat-answer-action-icon-light: #00796b;
$recall-chat-answer-action-icon-dark: #fff;

// History Page
$recall-history-question-light: #00796b;
$recall-history-question-hover-light: #373a36;
$recall-history-question-dark: #85c446;
$recall-history-question-hover-dark: #b8df7c;
$recall-history-icon-light: #068576;
$recall-history-icon-dark: #85c446;

// CRA Page
$recall-cra-link-light: #85c446;
$recall-cra-link-dark: #85c446;

// Dashboard Page
$recall-dashboard-chat-feedback-text-light: #006358;
$recall-dashboard-chat-feedback-text-dark: #85c446;

// Terms And Conditions Page
$recall-terms-conditions-title-link-light: #006358;
$recall-terms-conditions-title-link-dark: #89c925;

// Modal
$recall-modal-close-icon-light: #00796b;
$recall-modal-close-icon-hover-light: #004d40;
$recall-modal-close-icon-dark: #85c446;
$recall-modal-close-icon-hover-dark: #b8df7c;
