@import './_all_vars.sass'

:root
  --home-admin-text-colour: #{$recall-main-dark}
  --home-admin-edited-text: #{$primary--text--colour}
  --home-admin-separator: #{$recall-separator}
  --home-admin-icon: #{$primary--text--colour--lighter}
  --home-admin-cancel-btn-colour: #{$recall-text-menu}
  --home-admin-cancel-btn-background-colour: #{$recall-text-menu}
  --home-admin-cancel-btn-colour-hover: #{$recall-primary-white}
  --home-admin-cancel-btn-background-colour-hover: #{$recall-text-menu}

div:global(.pageThemeDark)
  --home-admin-text-colour: #{$recall-green-500-darkmode}
  --home-admin-edited-text: #{$recall-primary-white}
  --home-admin-separator: #{$recall-separator-darkmode}
  --home-admin-icon: #{$recall-green-500-darkmode}
  --home-admin-cancel-btn-colour: #{$recall-primary-white}
  --home-admin-cancel-btn-background-colour: #{$recall-text-menu}
  --home-admin-cancel-btn-colour-hover: #{$recall-text-menu}
  --home-admin-cancel-btn-background-colour-hover: #{$recall-primary-white}

.main
  display: flex
  flex-direction: column

.small
  display: flex
  margin-top: 16px
  color: var(--home-admin-text-colour)
  flex-direction: row
  gap: 16px

.systemBanner
  display: flex
  padding: 16px 0
  margin-top: 16px
  color: var(--home-admin-text-colour)
  flex-direction: row
  gap: 16px

.footer
  display: flex
  flex-direction: row
  align-items: center
  justify-content: flex-end
  margin-top: 16px

  button:global(.button)
    border-radius: 20px
    margin-right: 16px

    &:global(.is-primary), &:global(.is-primary:hover:enabled:not(.is-loading))
      color: var(--button-text)
      background-color: var(--button-background)
      border: 1px solod var(--button-background)

      &:hover
        color: var(--button-text)
        background-color: var(--button-background-hover)
        border: 1px solod var(--button-background-hover)
  
  .cancelEditSlides
    background-color: unset !important
    color: var(--home-admin-cancel-btn-colour) !important
    border: 1px solid var(--home-admin-cancel-btn-colour) !important

    &:hover
      background-color: var(--home-admin-cancel-btn-background-colour-hover) !important
      border: 1px solidvar(--home-admin-cancel-btn-background-colour-hover) !important
      color: var(--home-admin-cancel-btn-colour-hover) !important


.homeAdminTitle
  text-align: left !important
  font-size: 24px !important
  font-style: normal
  line-height: normal
  color: var(--home-admin-edited-text) !important

@media screen and (max-width: $breakpoint--desktop)
  .small
    display: flex
    flex-direction: column
