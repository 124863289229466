@import './_all_vars.sass'

.modalDivider
  position: relative
  border-bottom: 1px solid gray
  margin: 16px -24px 24px

.staticContent
  display: flex
  flex: 1
  flex-direction: column
  overflow: auto
  margin: 16px
  padding: 16px
  text-align: left

  h1
    font-family: inherit
    font-size: 30px
    font-weight: 400
    color: var(--terms-conditions-title-link-colour, $recall-main-dark)
    text-align: center
    
    sup
      font-size:6px
  p
    font-size: 16px

    &.note
      color: $recall-text-lighter
      font-style: italic

  a
    font-size: 16px
    color: var(--terms-conditions-title-link-colour, $recall-main-dark)

  .backToTopBtn
    text-align: right

    :global(.is-secondary)
      &, &:focus:enabled:not(.is-loading)
        color: var(--button-background)
        border: 1px solid var(--button-background)
        background-color: transparent

      &:hover, &:hover:enabled:not(.is-loading)
        color: var(--button-text)
        background-color: var(--button-background)

  .list
    font-size: 16px
    padding-inline-start: inherit
    counter-reset: item

    > li
      margin-top: 3em

      > span
        font-weight: bold

  .listSubLevel1
    font-size: 16px
    font-weight: 300
    padding-left: 0
    list-style-type: none

    li > span
      padding-right: 6px

    ul > li
      list-style-type: circle
      font-weight: 400


  .listSubLevel2
    padding-inline-start: 35px

    > li
      list-style-type: none !important

  ul
    margin-bottom: 2em

    li
      line-height: 1.5em
      margin-bottom: 1em

  .tableList
    li
      font-family: Arial, Helvetica, sans-serif
      font-size: 14px

.center
  justify-content: center

  p
    a
      color: var(--button-background)

  button:global(.is-primary), button:global(.is-primary:hover:enabled:not(.is-loading))
      color: var(--button-text)
      background-color: var(--button-background)

      &:hover
        color: var(button-text)
        background-color: var(--button-background-hover)

  button:global(.is-secondary), button:global(.is-secondary:hover:enabled:not(.is-loading))
      color: var(--button-background)
      border: 1px solid var(--button-background)
      background-color: transparent

      &:hover
        color: var(--button-text)
        border: 1px solid var(--button-background)
        background-color: var(--button-background)